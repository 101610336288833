<script lang="ts" setup>
const services = [
    {
        id: 0,
        name: "Lipo 360",
        text: "Tummy, Lower Back, Flanks Liposuction",
        path: "/service/specialization/lipo-360/39",
        image: {
            src: "/img/lipo-360.webp",
            alt: "Liposuction 360"
        },
        prices: {
            uk: 6000,
            pl: 17000,
            plInGBP: 3400
        }
    },
    {
        id: 1,
        name: "Gynecomastia",
        text: "Gyno with Lipo",
        path: "/service/specialization/gynecomastia/37",
        image: {
            src: "/img/gynecomastia.webp",
            alt: "Gynecomastia"
        },
        prices: {
            uk: 6000,
            pl: 12000,
            plInGBP: 2400
        }
    },
    {
        id: 2,
        name: "Abdominoplasty",
        text: "Tummy Tuck",
        path: "/service/specialization/tummy-tuck/7",
        image: {
            src: "/img/tummy-tuck.webp",
            alt: "Tummy Tuck"
        },
        prices: {
            uk: 8000,
            pl: 17000,
            plInGBP: 3400
        }
    },
    {
        id: 3,
        name: "Breast implants",
        text: "Boob Job",
        path: "/service/specialization/breast-implants/10",
        image: {
            src: "/img/breast-implants.webp",
            alt: "Breast implants"
        },
        prices: {
            uk: 7000,
            pl: 16000,
            plInGBP: 3200
        }
    }
];
const infoText = "Prices in PLN are binding, GBP rates are provided for reference only.";
</script>
<template>
    <SectionWrapper class="bg-white" watermark-text="deals">
        <h2 class="d-flex justify-center">
            <DecoratedTitle class="mx-auto text-center text-sm-left title-wrapper" text="Affordable deals" decorated-phrase="deals" />
        </h2>
        <VRow class="mt-4 mt-md-15 mt-lg-24">
            <VCol v-for="service in services" :key="service.id" cols="12" sm="6" md="3" class="fill-height">
                <VHover>
                    <template #default="{ isHovering, props }">
                        <NuxtLink :to="service.path" :title="service.name">
                            <VSheet v-bind="props" position="relative" style="overflow: hidden; z-index: 0" rounded="lg">
                                <Image
                                    :src="service.image.src"
                                    :alt="service.image.alt"
                                    sizes="100vh sm:320px md:200px lg:270px xl:320px"
                                    fit="cover"
                                    styles="height: clamp(12rem, 22vw, 26.75rem); display: block;"
                                    loading="lazy"
                                />
                                <div class="text">
                                    <div class="px-3 px-md-2 mx-lg-4 py-5 text-white">
                                        <div class="position-absolute" style="top: 1rem">
                                            <div class="text-h5 mb-1">{{ service.name }}</div>
                                            <div style="font-size: 0.7rem; line-height: 1.5">{{ service.text }}</div>
                                        </div>
                                        <div class="text-caption font-weight-light mt-2">
                                            <div>The price in Allmedica:</div>
                                            <div>
                                                {{ service.prices.plInGBP.toLocaleString() }} GBP* ({{ service.prices.pl.toLocaleString() }}
                                                PLN)
                                            </div>
                                            <VDivider class="opacity-50 my-1"></VDivider>
                                        </div>
                                        <div class="text-caption font-weight-light">
                                            <div>Typical price in UK:</div>
                                            <div>{{ service.prices.uk.toLocaleString() }} GBP</div>
                                        </div>
                                    </div>
                                </div>

                                <VOverlay
                                    :model-value="isHovering || false"
                                    contained
                                    class="pa-5 align-center justify-center disable-for-touch"
                                    scrim="purple-dark"
                                    opacity=".55"
                                >
                                    <VBtn :to="service.path" variant="outlined" rounded color="white" max-width="13.75rem" width="13vw" min-width="7rem">
                                        Explore
                                    </VBtn>
                                </VOverlay>
                            </VSheet>
                        </NuxtLink>
                    </template>
                </VHover>
            </VCol>
        </VRow>

        <small>*{{ infoText }}</small>
    </SectionWrapper>
</template>

<style lang="scss" scoped>
.text {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: flex-end;
    background: linear-gradient(180deg, rgba(6, 16, 77, 0.61) 0%, rgba(6, 16, 77, 0.2) 30%, rgba(6, 16, 77, 0.61) 80%);
}

@media (hover: none) {
    .disable-for-touch {
        display: none;
    }
}
</style>
